import React from "react"
import style from "./Headline.module.scss"
import PropTypes from "prop-types"
import cx from "classnames"
import { COLOR } from "../misc/constants"
import slugify from "slugify"
import { RichText } from "prismic-reactjs"

const Headline = props => {
  const { backgroundColor, heading1, paragraph, spacingBottom } = props

  const classNames = cx(style.container, {
    [style.yellow]: backgroundColor === COLOR.YELLOW,
    [style.spacingBottom]: spacingBottom,
  })

  return (
    <div
      className={classNames}
      id={slugify(RichText.asText(heading1).replace(/(&shy;|­|&#173;)/gi, ""), {
        lower: true,
      })}
    >
      <h1
        className={style.heading1}
        dangerouslySetInnerHTML={{ __html: RichText.asText(heading1) }}
      />
      <div className={style.lead}>
        <RichText render={paragraph} />
      </div>
    </div>
  )
}

export default Headline

Headline.propTypes = {
  backgroundColor: PropTypes.string,
  heading1: PropTypes.array,
  paragraph: PropTypes.array,
  spacingBottom: PropTypes.bool,
}
