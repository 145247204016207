import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import style from "./Accordion.module.scss"
import { nextFrame } from "../utils/helper"
import { Location } from "@reach/router"

const Accordion = (props) => {
  const { children, location } = props
  const [animate, setAnimate] = useState(false)
  const [measureTop, setMeasureTop] = useState(false)
  const [openIndex, setOpenIndex] = useState(-1)
  const [localIndex, setLocalIndex] = useState(-1)
  const hash = location.hash.substr(1)

  const handleToggle = async (index) => {
    setMeasureTop(true)
    setAnimate(false)
    setLocalIndex(index === openIndex ? -1 : index)
  }

  useEffect(() => {
    async function open() {
      await nextFrame()
      setMeasureTop(false)
      setOpenIndex(localIndex)
      setAnimate(true)
    }

    open()
  }, [setMeasureTop, measureTop, localIndex, setAnimate])

  return (
    <div className={style.container}>
      {React.Children.map(children, (child, index) => React.cloneElement(child, {
        index: index,
        isOpen: openIndex === index,
        onClick: handleToggle,
        measureTop: measureTop,
        hash,
        animate,
      }))}
    </div>
  )
}

Accordion.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
}

export default props => (
  <Location>
    {locationProps => <Accordion {...locationProps} {...props} />}
  </Location>
);
