import React from "react"

const IconDownload = () => (
  <svg viewBox="0 0 8 11">
    <path
      d="M2.186 10.168l.173.19.186-.178 4.694-4.502.189-.18-.189-.18L2.545.819 2.359.641l-.173.191-.728.806-.163.18.176.168 3.668 3.512L1.47 9.014l-.176.168.163.18.728.806z"
      strokeWidth={0.5}
    />
  </svg>
)

export default IconDownload
