import React from "react"
import style from "./Footer.module.scss"
import { graphql, StaticQuery, Link } from "gatsby"
import PropTypes from "prop-types"
import slugify from "slugify"
import cx from "classnames"

const Footer = props => {
  const {
    heading,
    address,
    phone_number,
    email_address,
    related_imprint_page,
    related_jobs_page,
    related_jobs_title,
  } = props.data.prismic.allFooters.edges[0].node
  const { isInPageLayout } = props

  const classNames = cx(style.container, {
    [style.isInPageLayout]: isInPageLayout,
  })

  return (
    <footer className={classNames}>
      <div className={style.headline}>
        {heading &&
          heading.map((heading, index) =>
            heading.spans[0] && heading.spans[0].data.url ? (
              <a
                className={style.heading}
                href={heading.spans[0].data.url}
                key={index}
              >
                {heading.text}
              </a>
            ) : (
              <span className={style.heading} key={index}>
                {heading.text}
              </span>
            )
          )}
      </div>

      <address className={style.physicalAddress}>
        {address &&
          address.map((line, index) => (
            <span className={style.addressElement} key={index}>
              {line.text}
            </span>
          ))}
      </address>
      <address className={style.contactInformation}>
        {phone_number[0].spans[0] && phone_number[0].spans[0].data.url ? (
          <a
            className={style.addressElement}
            href={phone_number[0].spans[0].data.url}
          >{`Tel. ${phone_number[0].text}`}</a>
        ) : (
          <span
            className={style.addressElement}
          >{`Tel. ${phone_number[0].text}`}</span>
        )}
        {email_address[0].spans[0] && email_address[0].spans[0].data.url ? (
          <a
            className={style.addressElement}
            href={email_address[0].spans[0].data.url}
          >
            {email_address[0].text}
          </a>
        ) : (
          <span className={style.addressElement}>{email_address[0].text}</span>
        )}
      </address>
      <ul className={style.linkList}>
        <li>
          <Link
            to={`/${related_jobs_page._meta.uid}#${slugify(
              related_jobs_title.replace(/(&shy;|­|&#173;)/gi, ""),
              {
                lower: true,
              }
            )}`}
            className={style.link}
          >
            Jobs
          </Link>
        </li>
        <li>
          <Link
            to={`/${related_imprint_page._meta.uid}`}
            className={style.link}
          >
            Impressum
          </Link>
        </li>
      </ul>
    </footer>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        prismic {
          allFooters {
            edges {
              node {
                heading
                address
                phone_number
                email_address
                related_imprint_page {
                  ... on PRISMIC_Imprint {
                    _meta {
                      uid
                    }
                  }
                }
                related_jobs_page {
                  ... on PRISMIC_Page {
                    _meta {
                      uid
                    }
                  }
                }
                related_jobs_title
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)

Footer.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allFooters: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              heading: PropTypes.arrayOf(
                PropTypes.shape({
                  text: PropTypes.string.isRequired,
                }).isRequired
              ).isRequired,
              address: PropTypes.arrayOf(
                PropTypes.shape({
                  text: PropTypes.string.isRequired,
                }).isRequired
              ).isRequired,
              phone_number: PropTypes.arrayOf(
                PropTypes.shape({
                  text: PropTypes.string.isRequired,
                }).isRequired
              ).isRequired,
            }).isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  isInPageLayout: PropTypes.bool,
}

Footer.defaultProps = {
  isInPageLayout: false,
}
