import React from "react"
import PropTypes from "prop-types"
import Header from "../components/Header"
import Footer from "../components/Footer"
import style from "./PageLayout.module.scss"
import VideoBackground from "../components/VideoBackground"

const PageLayout = ({
  children,
  headings,
  showFooter,
  posterMobile,
  posterDesktop,
  videoMobile,
  videoDesktop,
}) => (
  <>
    <VideoBackground
      videoMobile={videoMobile}
      videoDesktop={videoDesktop}
      posterDesktop={posterDesktop}
      posterMobile={posterMobile}
    />
    <Header layout="page" headings={headings} />

    <main className={style.main}>{children}</main>

    {showFooter && <Footer isInPageLayout={true} />}
  </>
)

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showFooter: PropTypes.bool,
  headings: PropTypes.arrayOf(PropTypes.array),
}

PageLayout.defaultProps = {
  showFooter: false,
}

export default PageLayout
