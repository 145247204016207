import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import style from "./RichTextSlice.module.scss"
import cx from "classnames"

const RichTextSlice = props => {
  const { richText, spacingBottom } = props

  const classNames = cx(style.container, {
    [style.spacingBottom]: spacingBottom,
  })

  return (
    <div className={classNames}>
      <RichText render={richText} />
    </div>
  )
}

RichTextSlice.propTypes = {
  richText: PropTypes.array,
  spacingBottom: PropTypes.bool,
}

export default RichTextSlice
