import React from "react"
import style from "./Map.module.scss"
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api"
import mapStyles from "../styles/mapStyles"
import PropTypes from "prop-types"
import cx from "classnames"

const Map = props => {
  const { latitude, longitude, spacingBottom } = props

  const mapCenter = {
    lat: latitude,
    lng: longitude,
  }

  const classNames = cx(style.container, {
    [style.spacingBottom]: spacingBottom,
  })

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_KEY,
  })

  if (!isLoaded) return null

  return (
    <GoogleMap
      mapContainerClassName={classNames}
      zoom={12}
      center={mapCenter}
      options={{
        disableDefaultUI: true,
        styles: mapStyles,
        backgroundColor: "#000000"
      }}>
      <Marker position={mapCenter}/>
    </GoogleMap>
  )
}

Map.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  spacingBottom: PropTypes.bool,
}

Map.defaultProps = {
  latitude: 47.24692499999999,
  longitude: 9.517834000000018,
}

export default Map
