import cx from "classnames"
import React from "react"
import PropTypes from "prop-types"
import Image from "./Image"
import style from "./Machine.module.scss"
import Slideshow from "./Slideshow"
import { RichText } from "prismic-reactjs"
import IconDownload from "../icons/iconDownload"
import Accordion from "./Accordion"
import AccordionItem from "./AccordionItem"
import { APPROVAL } from "../misc/constants"

const Machine = props => {
  const {
    machine: { images, type, datasheet, technical_data, application },
    fullWidth,
    spacingBottom,
  } = props

  const classNames = cx(style.container, {
    [style.spacingBottom]: spacingBottom,
    [style.marginBottomHeading]: !datasheet,
  })

  return (
    <div className={classNames}>
      {images.length > 0 &&
        (images[0].image && images.length === 1 ? (
          <div
            className={cx(style.imageContainer, {
              [style.fullWidth]: fullWidth === APPROVAL.YES,
            })}
          >
            <Image image={images[0].image} />
          </div>
        ) : (
          <Slideshow
            fullWidth={fullWidth === APPROVAL.YES}
            withinComponent={true}
            images={images}
          />
        ))}
      <h2
        className={style.heading2}
        dangerouslySetInnerHTML={{ __html: RichText.asText(type) }}
      />
      {datasheet && (
        <a
          className={style.downloadLink}
          href={datasheet.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={style.label}>Download Datenblatt</span>
          <span className={style.iconContainer}>
            <IconDownload />
          </span>
        </a>
      )}

      <div className={style.accordionContainer}>
        <Accordion>
          <AccordionItem
            header={{
              tag: "h3",
              content: "Technische Daten",
              isGrowing: false,
            }}
            body={
              <div className={style.bodyTechnicalData}>
                {technical_data.map((item, index) => (
                  <React.Fragment key={index}>
                    <span className={style.attributeTechnicalData}>
                      {item.attribute}
                    </span>
                    <span className={style.valueTechnicalData}>
                      {item.value}
                    </span>
                    <div className={style.separator} />
                  </React.Fragment>
                ))}
              </div>
            }
          />
          <AccordionItem
            header={{ tag: "h3", content: "Einsatzgebiete", isGrowing: false }}
            body={
              <div>
                {application.map((item, index) => (
                  <span key={index} className={style.itemApplication}>
                    {item.attribute}
                  </span>
                ))}
              </div>
            }
          />
        </Accordion>
      </div>
    </div>
  )
}

Machine.propTypes = {
  fullWidth: PropTypes.string,
  machine: PropTypes.shape({
    images: PropTypes.array,
    type: PropTypes.array,
    datasheet: PropTypes.shape({
      url: PropTypes.string,
    }),
    technical_data: PropTypes.array,
    application: PropTypes.array,
  }),
  spacingBottom: PropTypes.bool,
}

Machine.defaultProps = {
  technical_data: [],
  application: [],
}

export default Machine
