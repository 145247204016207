export const SLICE = Object.freeze({
  HEADLINE: "headline",
  ARTICLE: "article",
  SLIDESHOW: "slideshow",
  JOBS: "jobs",
  MAP: "map",
  RICH_TEXT: "rich_text",
  PEOPLE: "people",
  IMAGE: "image",
  MACHINE: "machine",
  JOB_TEASER: "job_teaser",
})

export const COLOR = Object.freeze({
  BLACK: "black",
  YELLOW: "yellow",
})

export const APPROVAL = Object.freeze({
  YES: "yes",
  NO: "no",
})
