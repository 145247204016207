import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import style from "./VideoBackground.module.scss"
import { breakpointLarge } from "../styles/_variables.scss"
import cx from "classnames"

const VideoBackground = props => {
  const {
    videoMobile,
    videoDesktop,
    playVideo,
    posterMobile,
    posterDesktop,
  } = props

  const videoRef = useRef()
  const [videoSrc, setVideoSrc] = useState("")
  const [posterSrc, setPosterSrc] = useState("")
  const [posterAlt, setPosterAlt] = useState("#")
  const [isVideoLoaded, setVideoLoaded] = useState(false)
  const [isPosterLoaded, setPosterLoaded] = useState(false)

  useEffect(() => {
    const setVideoSource = name => setVideoSrc(`/${name}`)
    const setPosterSource = (src, isDesktop) =>
      setPosterSrc(`${src}&w=${isDesktop ? 640 : 180}&blur=40&q=40`)
    const mql = window.matchMedia(`(min-width: ${breakpointLarge})`)

    const setStates = isDesktop => {
      setPosterSource(
        isDesktop ? posterDesktop.url : posterMobile.url,
        isDesktop
      )
      setVideoSource(isDesktop ? videoDesktop.name : videoMobile.name)
      setPosterAlt(isDesktop ? posterDesktop.alt : posterMobile.alt)
    }

    const handleMediaChange = event => setStates(event.matches)
    mql.addListener(handleMediaChange)
    setStates(mql.matches)

    return () => mql.removeListener(handleMediaChange)
  }, [
    videoDesktop.name,
    videoMobile.name,
    posterMobile.url,
    posterMobile.alt,
    posterDesktop.url,
    posterDesktop.alt,
  ])

  useEffect(() => {
    if (playVideo) {
      videoRef.current.currentTime = 0
      videoRef.current.play()
    }
  }, [playVideo, videoSrc])

  useEffect(() => {
    if (videoSrc) {
      videoRef.current.play() //force preloading on safari
    }
  }, [videoSrc])

  return (
    <div className={style.container}>
      {posterSrc && (
        <img
          className={cx(style.element, {
            [style.isLoaded]: isPosterLoaded,
          })}
          alt={posterAlt}
          onLoad={() => setPosterLoaded(true)}
          src={posterSrc}
        />
      )}

      {videoSrc && (
        <video
          className={cx(style.element, { [style.isLoaded]: isVideoLoaded })}
          ref={videoRef}
          src={videoSrc}
          onLoadedData={() => setVideoLoaded(true)}
          muted
          loop
          playsInline
          preload="auto"
        />
      )}
    </div>
  )
}

VideoBackground.propTypes = {
  videoMobile: PropTypes.object,
  videoDesktop: PropTypes.object,
  playVideo: PropTypes.bool,
}

export default VideoBackground
