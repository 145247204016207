import cx from "classnames"
import React from "react"
import PropTypes from "prop-types"
import style from "./People.module.scss"
import Person from "./Person"

const People = props => {
  const { persons, spacingBottom } = props

  const classNames = cx(style.container, {
    [style.spacingBottom]: spacingBottom,
  })

  return (
    <div className={classNames}>
      {persons.map((person, index) => (
        <Person
          key={index}
          portrait={person.portrait}
          firstName={person.first_name}
          lastName={person.last_name}
          role={person.role}
          phoneNumber={person.phone_number}
          emailAddress={person.email_address}
        />
      ))}
    </div>
  )
}

People.propTypes = {
  persons: PropTypes.array,
  spacingBottom: PropTypes.bool,
}

export default People
