import React, { useState } from "react"
import PropTypes from "prop-types"
import useNativeLazyLoading from "@charlietango/use-native-lazy-loading"
import { useInView } from "react-intersection-observer"
import style from "./Image.module.scss"
import cx from "classnames"

const Image = ({ image }) => {
  const supportsLazyLoading = useNativeLazyLoading()
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "200px 0px",
  })

  const [isLoaded, setIsLoaded] = useState(false)

  if (!image) return null

  const srcSet = Object.values(image)
    .filter(
      item =>
        item &&
        typeof item.url === "string" &&
        item.dimensions &&
        typeof item.dimensions.width === "number"
    )
    .map(item => `${item.url} ${item.dimensions.width}w`)
    .join(", ")

  const classNames = cx(style.element, {
    [style.isLoaded]: isLoaded,
  })

  return (
    <div
      className={style.container}
      ref={supportsLazyLoading === false ? ref : undefined}
    >
      {inView || supportsLazyLoading ? (
        <img
          className={classNames}
          src={image.url}
          srcSet={srcSet}
          alt={image.alt}
          loading="lazy"
          onLoad={() => setIsLoaded(true)}
        />
      ) : null}
    </div>
  )
}

Image.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
}

export default Image
