import React from "react"
import PropTypes from "prop-types"
import style from "./Person.module.scss"
import Image from "./Image"
import RichText from "prismic-reactjs/src/Component"

const Person = ({
  portrait,
  firstName,
  lastName,
  role,
  phoneNumber,
  emailAddress,
}) => {
  return (
    <div className={style.container}>
      <div className={style.imageContainer}>
        {portrait && <Image image={portrait} />}
      </div>
      <span className={style.name}>{`${firstName} ${lastName}`}</span>
      <span className={style.role}>{role}</span>
      <address className={style.address}>
        <RichText render={phoneNumber} />
        <RichText render={emailAddress} />
      </address>
    </div>
  )
}

Person.propTypes = {
  portrait: PropTypes.shape({
    url: PropTypes.string,
  }),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  role: PropTypes.string,
  phoneNumber: PropTypes.arrayOf(PropTypes.object),
  emailAddress: PropTypes.arrayOf(PropTypes.object),
}

export default Person
