import React from "react"
import style from "./IconChevron.module.scss"
import cx from "classnames"

const IconChevron = props => {
  const classNames = cx(style.svg, { [style.isActive]: props.isActive })

  return (
    <svg className={classNames} viewBox="0 0 23 14">
      <path
        className={`${style.path} ${style.pathOne}`}
        d="M0 2.12L2.121 0l11.314 11.313-2.121 2.121z"
      />
      <path
        className={`${style.path} ${style.pathTwo}`}
        d="M20.46.025l2.12 2.121-11.288 11.289-2.121-2.121z"
      />
    </svg>
  )
}

export default IconChevron
