import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Accordion from "./Accordion"
import style from "./Jobs.module.scss"
import RichText from "prismic-reactjs/src/Component"
import AccordionItem from "./AccordionItem"
import slugify from "slugify"
import cx from "classnames"

const Jobs = props => {
  const jobs = props.data.prismic.allJobs.edges

  const classNames = cx(style.container, {
    [style.spacingBottom]: props.spacingBottom,
  })

  return (
    <div className={classNames}>
      <Accordion>
        {jobs.map((job, index) => (
          <AccordionItem
            key={index}
            id={slugify(
              `${RichText.asText(job.node.title).replace(
                /(&shy;|­|&#173;)/gi,
                ""
              )}-${job.node.workload}`,
              { lower: true }
            )}
            header={{
              tag: "h2",
              content: `${RichText.asText(job.node.title)} ${
                job.node.workload
              }`,
              isGrowing: true,
            }}
            body={<RichText render={job.node.description} />}
          />
        ))}
      </Accordion>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        prismic {
          allJobs {
            edges {
              node {
                title
                workload
                description
                _meta {
                  id
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Jobs data={data} {...props} />}
  />
)

Jobs.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allJobs: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              _meta: PropTypes.shape({
                id: PropTypes.string.isRequired,
              }).isRequired,
              workload: PropTypes.string.isRequired,
              description: PropTypes.arrayOf(
                PropTypes.shape({
                  text: PropTypes.string.isRequired,
                }).isRequired
              ).isRequired,
              title: PropTypes.arrayOf(
                PropTypes.shape({
                  text: PropTypes.string.isRequired,
                }).isRequired
              ).isRequired,
            }).isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  spacingBottom: PropTypes.bool,
}
