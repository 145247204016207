import cx from "classnames"
import React from "react"
import PropTypes from "prop-types"
import style from "./JobTeaser.module.scss"
import { RichText } from "prismic-reactjs"
import slugify from "slugify"
import { Link } from "gatsby"
import Job from "./Job"

const JobTeaser = props => {
  const {
    heading1,
    alternativeText,
    job1,
    job2,
    job3,
    relatedPage,
    relatedHeadline,
    spacingBottom,
  } = props

  const classNames = cx(style.container, {
    [style.spacingBottom]: spacingBottom,
    [style.withAlternativeText]: !(job1 && job2 && job3),
  })

  return (
    <div className={classNames}>
      <h1 className={style.heading1}>{RichText.asText(heading1)}</h1>
      {job1 && job2 && job3 ? (
        <div className={style.jobs}>
          <Job job={job1} relatedPage={relatedPage} />
          <Job job={job2} relatedPage={relatedPage} />
          <Job job={job3} relatedPage={relatedPage} />
        </div>
      ) : (
        <div className={style.alternativeText}>
          <RichText render={alternativeText} />
        </div>
      )}

      <div className={style.linkContainer}>
        <Link
          to={`/${relatedPage}#${slugify(
            relatedHeadline.replace(/(&shy;|­|&#173;)/gi, ""),
            { lower: true }
          )}`}
          className={style.link}
        >
          Alle Jobs anzeigen
        </Link>
      </div>
    </div>
  )
}

JobTeaser.propTypes = {
  heading1: PropTypes.array,
  alternativeText: PropTypes.arrayOf(PropTypes.object),
  job1: PropTypes.object,
  job2: PropTypes.object,
  job3: PropTypes.object,
  relatedPage: PropTypes.string,
  relatedHeadline: PropTypes.string,
  spacingBottom: PropTypes.bool,
}

export default JobTeaser
