import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import RichText from "prismic-reactjs/src/Component"
import style from "./Job.module.scss"
import slugify from "slugify"

const Job = ({ job: { title, alternative_title, workload }, relatedPage }) => (
  <div className={style.container}>
    <div className={style.alternativeTitle}>
      <RichText render={alternative_title} />
    </div>
    <h3 className={style.title}>{RichText.asText(title)}</h3>
    <Link
      className={style.link}
      to={`/${relatedPage}#${slugify(
        `${RichText.asText(title).replace(
          /(&shy;|­|&#173;)/gi,
          ""
        )}-${workload}`,
        { lower: true }
      )}`}
      state={{ modal: true }}
    >
      <span className={style.label}>Zum Job</span>
    </Link>
  </div>
)

Job.propTypes = {
  job: PropTypes.shape({
    title: PropTypes.array,
    alternative_title: PropTypes.array,
    workload: PropTypes.string,
  }),
  relatedPage: PropTypes.string,
}

export default Job
