import React, { useEffect, useState } from "react"
import style from "./Slideshow.module.scss"
import Image from "./Image"
import cx from "classnames"
import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"
import { slideshowDuration } from "../styles/_variables.scss"

const Slideshow = props => {
  const { images, fullWidth, withinComponent, spacingBottom } = props
  const [activeIndex, setActiveIndex] = useState(-1)
  const [isInitial, setInitial] = useState(true)
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  })

  const classNames = cx(style.container, {
    [style.fullWidth]: fullWidth,
    [style.withinComponent]: withinComponent,
    [style.spacingBottom]: spacingBottom && !withinComponent,
  })

  useEffect(() => {
    const setImage = () => {
      const newIndex = activeIndex < images.length - 1 ? activeIndex + 1 : 0
      setActiveIndex(newIndex)
    }

    let timeoutID = null

    if (inView) {
      if (isInitial) {
        setImage()
        setInitial(false)
      } else {
        timeoutID = setTimeout(() => setImage(), slideshowDuration)
      }
    }

    return () => {
      clearTimeout(timeoutID)
    }
  }, [inView, activeIndex, images, isInitial, setInitial])

  return (
    <div className={classNames} ref={ref}>
      {images.map(({ image }, index) => {
        return image ? (
          <div
            key={index}
            className={cx(style.slide, {
              [style.isVisible]: index === activeIndex,
            })}
          >
            <Image image={image} />
            <div className={style.progress} />
          </div>
        ) : null
      })}
    </div>
  )
}

export default Slideshow

Slideshow.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  fullWidth: PropTypes.bool,
  withinComponent: PropTypes.bool,
  spacingBottom: PropTypes.bool,
}
