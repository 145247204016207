import React from "react"
import { RichText } from "prismic-reactjs"
import Image from "./Image"
import Slideshow from "./Slideshow"
import style from "./Article.module.scss"
import PropTypes from "prop-types"
import cx from "classnames"

const Article = props => {
  const { heading2, paragraph, fullWidth, images, spacingBottom } = props

  const classNames = cx(style.container, {
    [style.spacingBottom]: spacingBottom,
  })

  return (
    <div className={classNames}>
      {images.length > 0 &&
        (images?.[0]?.image && images.length === 1 ? (
          <div
            className={cx(style.imageContainer, {
              [style.fullWidth]: fullWidth,
            })}
          >
            <Image image={images[0].image} />
          </div>
        ) : (
          <Slideshow
            fullWidth={fullWidth}
            withinComponent={true}
            images={images}
          />
        ))}
      <h2
        className={style.heading2}
        dangerouslySetInnerHTML={{ __html: RichText.asText(heading2) }}
      />
      <div className={style.paragraph}>{RichText.render(paragraph)}</div>
    </div>
  )
}

Article.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  fullWidth: PropTypes.bool,
  spacingBottom: PropTypes.bool,
}

export default Article
