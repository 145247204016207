import React, { useEffect, useLayoutEffect, useRef } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import style from "./AccordionItem.module.scss"
import IconChevron from "../icons/IconChevron"
import TWEEN from "@tweenjs/tween.js"

const AccordionItem = ({
  index,
  id,
  header: { tag, content, isGrowing },
  body,
  isOpen,
  onClick,
  measureTop,
  animate,
  hash,
}) => {
  const prevHashRef = useRef(null)
  const tweenRef = useRef(null)
  const topRef = useRef(0)
  const containerRef = useRef()
  const TagName = tag

  useEffect(() => {
    if (hash !== prevHashRef.current && id === hash && !isOpen) {
      prevHashRef.current = hash
      onClick(index)
    }
  }, [id, index, hash, onClick, isOpen, measureTop])

  useEffect(() => {
    if (isOpen) {
      const { top } = containerRef.current.getBoundingClientRect()
      if (top < 0 || top > window.innerHeight) {
        containerRef.current.scrollIntoView()
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (measureTop) {
      topRef.current = containerRef.current.getBoundingClientRect().top
    }
  }, [measureTop])

  useLayoutEffect(() => {
    if (animate) {
      const { top } = containerRef.current.getBoundingClientRect()
      const diff = topRef.current - top
      if (diff === 0) return

      containerRef.current.style.transform = `translateY(${diff}px)`
      tweenRef.current = new TWEEN.Tween({ top: diff })
        .to({ top: 0 }, 650)
        .easing(TWEEN.Easing.Exponential.Out)
        .onUpdate(
          ({ top }) =>
            (containerRef.current.style.transform = `translateY(${top}px)`)
        )
        .onComplete(() =>
          containerRef.current.style.removeProperty("transform")
        )
        .start()
    }

    return () => {
      if (tweenRef.current) {
        tweenRef.current.stop()
      }
    }
  }, [animate])

  return (
    <div
      className={cx(style.container, { [style.isOpen]: isOpen })}
      id={id}
      ref={containerRef}
    >
      <div className={style.headerContainer} onClick={() => onClick(index)}>
        <div className={style.header}>
          <TagName
            className={cx(style.label, {
              [style.isGrowing]: isOpen && isGrowing,
            })}
          >
            {content}
          </TagName>
          <IconChevron isActive={isOpen} />
        </div>
      </div>
      <div className={style.body}>{body}</div>
    </div>
  )
}

AccordionItem.propTypes = {
  index: PropTypes.number,
  id: PropTypes.string,
  header: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    isGrowing: PropTypes.bool.isRequired,
  }),
  body: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  hash: PropTypes.string,
}

export default AccordionItem
