import React from "react"
import PropTypes from "prop-types"
import style from "./ImageSlice.module.scss"
import Image from "./Image"
import cx from "classnames"
import { APPROVAL } from "../misc/constants"

const ImageSlice = props => {
  const { fullWidth, image, spacingBottom } = props

  const classNames = cx(style.container, {
    [style.fullWidth]: fullWidth === APPROVAL.YES,
    [style.spacingBottom]: spacingBottom,
  })

  return <div className={classNames}>{image && <Image image={image} />}</div>
}

ImageSlice.propTypes = {
  fullWidth: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  spacingBottom: PropTypes.bool,
}

export default ImageSlice
