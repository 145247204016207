import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import PageLayout from "../layouts/PageLayout"
import { SLICE } from "../misc/constants"
import Headline from "../components/Headline"
import Slideshow from "../components/Slideshow"
import Article from "../components/Article"
import Jobs from "../components/Jobs"
import Map from "../components/Map"
import RichTextSlice from "../components/RichTextSlice"
import People from "../components/People"
import ImageSlice from "../components/ImageSlice"
import Machine from "../components/Machine"
import JobTeaser from "../components/JobTeaser"

export const query = graphql`
  query PageQuery($uid: String!) {
    prismic {
      page(lang: "de-ch", uid: $uid) {
        _meta {
          lang
          uid
        }
        page_title
        poster_desktop
        poster_mobile
        video_desktop {
          ... on PRISMIC__FileLink {
            name
          }
        }
        video_mobile {
          ... on PRISMIC__FileLink {
            name
          }
        }
        site_title
        site_description
        site_image
        body {
          ... on PRISMIC_PageBodyHeadline {
            type
            primary {
              background_color
              heading_1
              paragraph
              spacing_bottom
            }
          }
          ... on PRISMIC_PageBodyArticle {
            type
            primary {
              heading_2
              paragraph
              full_width_images
              spacing_bottom
            }
            fields {
              image
            }
          }
          ... on PRISMIC_PageBodySlideshow {
            type
            primary {
              full_width_images
              spacing_bottom
            }
            fields {
              image
            }
          }
          ... on PRISMIC_PageBodyJobs {
            type
            primary {
              spacing_bottom
            }
          }
          ... on PRISMIC_PageBodyMap {
            type
            primary {
              map
              spacing_bottom
            }
          }
          ... on PRISMIC_PageBodyRich_text {
            type
            primary {
              rich_text
              spacing_bottom
            }
          }
          ... on PRISMIC_PageBodyPeople {
            type
            fields {
              email_address
              first_name
              last_name
              phone_number
              portrait
              role
            }
            primary {
              spacing_bottom
            }
          }
          ... on PRISMIC_PageBodyImage {
            type
            primary {
              full_width_image
              image
              spacing_bottom
            }
          }
          ... on PRISMIC_PageBodyMachine {
            type
            primary {
              full_width_images
              relation {
                ... on PRISMIC_Machine {
                  type
                  images {
                    image
                  }
                  datasheet {
                    ... on PRISMIC__FileLink {
                      url
                    }
                  }
                  application {
                    attribute
                  }
                  technical_data {
                    attribute
                    value
                  }
                }
              }
              spacing_bottom
            }
          }
          ... on PRISMIC_PageBodyJob_teaser {
            type
            primary {
              title
              alternative_text
              job_01 {
                ... on PRISMIC_Job {
                  title
                  alternative_title
                  workload
                }
              }
              job_02 {
                ... on PRISMIC_Job {
                  title
                  alternative_title
                  workload
                }
              }
              job_03 {
                ... on PRISMIC_Job {
                  title
                  alternative_title
                  workload
                }
              }
              related_headline_title
              related_page {
                ... on PRISMIC_Page {
                  _meta {
                    uid
                  }
                }
              }
              spacing_bottom
            }
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  if (!data.prismic.page) {
    return null
  }
  const {
    body,
    _meta,
    poster_desktop,
    poster_mobile,
    video_mobile,
    video_desktop,
    site_title,
    site_description,
    site_image,
  } = data.prismic.page
  let headings
  if (body) {
    headings = body
      .filter(slice => slice.type === SLICE.HEADLINE)
      .map(headline => headline.primary.heading_1)
  }

  return (
    <PageLayout
      showFooter={true}
      headings={headings}
      posterDesktop={poster_desktop}
      posterMobile={poster_mobile}
      videoMobile={video_mobile}
      videoDesktop={video_desktop}
    >
      <SEO
        title={site_title}
        description={site_description}
        image={site_image}
        lang={_meta.lang}
        slug={_meta.uid}
      />

      {body &&
        body.map((slice, index) => {
          switch (slice.type) {
            case SLICE.HEADLINE:
              return (
                <Headline
                  key={index}
                  backgroundColor={slice.primary.background_color}
                  heading1={slice.primary.heading_1}
                  paragraph={slice.primary.paragraph}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            case SLICE.ARTICLE:
              return (
                <Article
                  key={index}
                  heading2={slice.primary.heading_2}
                  paragraph={slice.primary.paragraph}
                  fullWidth={slice.primary.full_width_images === "yes"}
                  images={slice.fields}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            case SLICE.SLIDESHOW:
              return slice.fields[0].image && slice.fields.length === 1 ? (
                <ImageSlice
                  key={index}
                  fullWidth={slice.primary.full_width_images}
                  image={slice.fields[0].image}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              ) : (
                <Slideshow
                  key={index}
                  fullWidth={slice.primary.full_width_images === "yes"}
                  images={slice.fields}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            case SLICE.JOBS:
              return (
                <Jobs
                  key={index}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            case SLICE.MAP:
              return (
                <Map
                  key={index}
                  latitude={slice.primary.map.latitude}
                  longitude={slice.primary.map.longitude}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            case SLICE.RICH_TEXT:
              return (
                <RichTextSlice
                  key={index}
                  richText={slice.primary.rich_text}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            case SLICE.PEOPLE:
              return (
                <People
                  key={index}
                  persons={slice.fields}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            case SLICE.IMAGE:
              return (
                <ImageSlice
                  key={index}
                  fullWidth={slice.primary.full_width_image}
                  image={slice.primary.image}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            case SLICE.MACHINE:
              return (
                <Machine
                  key={index}
                  machine={slice.primary.relation}
                  fullWidth={slice.primary.full_width_images}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            case SLICE.JOB_TEASER:
              return (
                <JobTeaser
                  key={index}
                  heading1={slice.primary.title}
                  alternativeText={slice.primary.alternative_text}
                  job1={slice.primary.job_01}
                  job2={slice.primary.job_02}
                  job3={slice.primary.job_03}
                  relatedPage={slice.primary.related_page._meta.uid}
                  relatedHeadline={slice.primary.related_headline_title}
                  spacingBottom={slice.primary.spacing_bottom === "yes"}
                />
              )
            default:
              return null
          }
        })}
    </PageLayout>
  )
}
